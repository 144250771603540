import React, { FC } from 'react';
import { Quote } from './ImageNextToContent.styles';
import quote from 'svg/quote.svg';

export const QuoteGradientIcon: FC<{
  id?: string;
  startColor?: string;
  endColor?: string;
}> = ({ id, startColor, endColor }) => {
  if (!id || !startColor || !endColor) {
    return null;
  }

  return (
    <>
      <svg
        style={{ width: 0, height: 0, position: 'absolute' }}
        aria-hidden="true"
        focusable="false"
      >
        <linearGradient id={`gradient-${id}`}>
          <stop offset="0%" stop-color={startColor} />
          <stop offset="100%" stop-color={endColor} />
        </linearGradient>
      </svg>
      <Quote icon={quote} gradient={`gradient-${id}`} />
    </>
  );
};
