import styled from 'styled-components';
import { height, HeightProps } from 'styled-utils';

export const LoadingWrapper = styled.div<HeightProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  ${height};
`;
