import { css } from 'styled-components';
import { fontSize, fontWeight, lineHeight } from '../styled-utils';

type PoppinsType = {
  fontSize: string;
  lineHeight: string;
  fontWeight?: number;
};

const display = {
  fontSize: '96px',
  lineHeight: '108px',
  fontWeight: 700,
};

const h1 = {
  fontSize: '48px',
  lineHeight: '64px',
  fontWeight: 700,
};

const h2 = {
  fontSize: '32px',
  lineHeight: '40px',
  fontWeight: 700,
};

const h3 = {
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 700,
};

const h4 = {
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 700,
};

const label = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 700,
};

const secondary = {
  fontSize: '14px',
  lineHeight: '20px',
};

const secondaryBold = {
  ...secondary,
  fontWeight: 700,
};

const tertiary = {
  fontSize: '12px',
  lineHeight: '18px',
};

const tertiaryBold = {
  ...tertiary,
  fontWeight: 700,
};

export const combineTypography = (
  mobile: PoppinsType,
  desktop?: PoppinsType
) => {
  if (!desktop) {
    desktop = mobile;
  }

  const important = (val: string | number) => `${val}`;

  return css`
    ${fontSize({
      fontSize: [important(mobile.fontSize), important(desktop.fontSize)],
    })};
    ${lineHeight({
      lineHeight: [important(mobile.lineHeight), important(desktop.lineHeight)],
    })};
    ${fontWeight({
      fontWeight: [
        important(mobile.fontWeight || 400),
        important(desktop.fontWeight || 400),
      ],
    })};
  `;
};

export const poppinsFonts = css`
  a {
    font-weight: 700 !important;
  }

  .display,
  p.display {
    ${combineTypography(h1, display)};
  }

  h1,
  .heading1,
  p.heading1 {
    ${combineTypography(h3, h1)};
  }

  h2,
  p.heading2 {
    ${combineTypography(h4, h2)};
  }

  h3,
  p.heading3 {
    ${combineTypography(label, h3)};
  }

  h4,
  p.heading4 {
    ${combineTypography(secondaryBold, h4)};
  }

  p.label {
    ${combineTypography(label)};
  }

  p.small {
    ${combineTypography(secondary)};
  }

  p.xsmall {
    ${combineTypography(tertiary)};
  }

  p.tertiaryBold {
    ${combineTypography(tertiaryBold)};
  }
`;
