import styled from 'styled-components';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { fontSize, height, lineHeight, minWidth } from 'styled-utils';
import { Button } from 'components/Common/Button';

export const LogoLink = styled.a<{ isMobile?: boolean }>`
  display: flex;
  height: ${({ isMobile }) => (isMobile ? '32px' : '46px')};
`;

export const DesktopLogo = styled(GatsbyImage)``;
export const MobileLogo = styled(GatsbyImage)``;

export const ContainerOverlay = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  transition: 0.5s;
  margin: 20px auto;
  padding: 0 30px;
`;
export const ContextOverlay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoginButton = styled(Button)`
  ${fontSize({ fontSize: ['12px', '14px'] })};
  ${minWidth({ minWidth: ['84px', '130px'] })};
  ${lineHeight({ lineHeight: ['24px', '40px'] })};
  ${height({ height: ['32px', '30px'] })};
  border: 3px solid currentColor;
`;
