import React, { createContext, useContext, useState } from 'react';

export interface AppContextProps {
  isAnyTileActive: boolean;
  clearAll: () => void;
  addActiveTile: (id: number) => void;
  removeActiveTile: (id: number) => void;
}

export const MarqueeContext = createContext<AppContextProps>(
  {} as AppContextProps
);

export const useMarquee = () => useContext(MarqueeContext);

export const MarqueeProvider: React.FunctionComponent = (props) => {
  const [activeTiles, setActiveTiles] = useState<Record<number, boolean>>({});

  const addActiveTile = (id: number) => {
    setActiveTiles({ [id]: true });
  };

  const clearAll = () => {
    console.log('Clear all - activeTiles', activeTiles);
    setActiveTiles((currentState) => {
      console.log(currentState);
      return {};
    });
  };

  const removeActiveTile = (id: number) => {
    setActiveTiles((currentTiles) => {
      delete currentTiles[id];
      return { ...currentTiles };
    });
  };

  return (
    <MarqueeContext.Provider
      value={{
        isAnyTileActive: Boolean(Object.keys(activeTiles).length),
        clearAll,
        addActiveTile,
        removeActiveTile,
      }}
      {...props}
    />
  );
};
