import styled from 'styled-components';
import { BackgroundColorProps } from 'styled-system';

import { color } from 'styled-utils';

export const ParallaxCardsWrapper = styled.div<BackgroundColorProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  ${color}
`;
