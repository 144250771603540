import React, { FC } from 'react';
import { SanityRichText } from 'components/Common/SanityRichText';

import { CardType } from './ParallaxCards';
import {
  ParallaxCardWrapper,
  Body,
  Text,
  Image,
} from './ImageNextToContent.styles';
import { BackgroundColorProps } from 'styled-system';

import { QuoteGradientIcon } from './QuoteGradientIcon';

export const ImageNextToContent: FC<
  { card: CardType } & BackgroundColorProps
> = ({ card, bg }) => (
  <ParallaxCardWrapper bg={bg}>
    {card.image ? (
      <Image image={card.image?.asset?.gatsbyImageData} alt={'Image'} />
    ) : null}
    <Body>
      <QuoteGradientIcon
        id={card._key}
        startColor={card.quotationMarkStartColor?.hex}
        endColor={card.quotationMarkEndColor?.hex}
      />
      {card._rawText ? (
        <Text>
          <SanityRichText blocks={card._rawText} />
        </Text>
      ) : null}
    </Body>
  </ParallaxCardWrapper>
);
