import React from 'react';
import { SpaceProps } from 'styled-utils';

import { SanityStandardRichTextWithInsertsFragment } from 'graphql-types';

import { StandardRichText } from 'components/StandardRichText/StandardRichText';
import {
  AssetsPicker,
  AssetsPickerProps,
} from 'components/AssetsPicker/AssetsPicker';

import { Left, Right, Text, Media, Section } from './SplitMedia.styles';

export type TextLocation = 'left' | 'right';

export type SplitMediaProps = AssetsPickerProps & {
  backgroundColor: string;
  padding?: SpaceProps['p'];
  textLocation?: TextLocation;
  richTextContent?: SanityStandardRichTextWithInsertsFragment;
};

export const SplitMedia: React.FC<SplitMediaProps> = ({
  padding,
  backgroundColor,
  richTextContent,
  textLocation = 'right',
  ...assets
}) => {
  const content = richTextContent ? (
    <Text>
      <StandardRichText fields={richTextContent} />
    </Text>
  ) : null;

  const media = <Media>{assets ? <AssetsPicker {...assets} /> : null}</Media>;

  return (
    <Section p={padding} backgroundColor={backgroundColor}>
      <Left>{textLocation === 'left' ? content : media}</Left>
      <Right>{textLocation === 'right' ? content : media}</Right>
    </Section>
  );
};
