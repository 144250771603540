import React from 'react';

import { Maybe, SanityColor, MediaWithTextFragment } from 'graphql-types';

import { sanityToAssetsPickerProps } from 'components/AssetsPicker/assets-picker.helper';

import { SplitMedia, SplitMediaProps, TextLocation } from './SplitMedia';

const getColor = (
  color: SanityColor | Maybe<Pick<SanityColor, 'hex'>>
): string => {
  return color?.hex || '';
};

export const SanitySplitMedia: React.FC<{ data: MediaWithTextFragment }> = ({
  data,
}) => (
  <SplitMedia
    textLocation={data.textLocation as TextLocation}
    backgroundColor={getColor(data.backgroundColor)}
    richTextContent={data.text}
    padding={data.padding as SplitMediaProps['padding']}
    {...sanityToAssetsPickerProps(data.mediaAsset)}
  />
);
