import React from 'react';

import { MarqueeProvider } from './MarqueeContext';
import { MarqueeItem, MarqueeItemType } from './MarqueeItemType';
import { VideoList } from './Marquee.styles';

import { GenericMarquee } from './GenericMarquee';
import { SanityMarqueeFragment } from 'graphql-types';

export const Marquee: React.FC<{
  data: SanityMarqueeFragment;
}> = ({ data }) => {
  return (
    <MarqueeProvider>
      <GenericMarquee loopDuration={data.loopDuration}>
        <VideoList>
          {data.items?.map((item: MarqueeItemType, index: number) => (
            <MarqueeItem key={index} id={index} item={item} />
          ))}
        </VideoList>
      </GenericMarquee>
    </MarqueeProvider>
  );
};
