import styled from 'styled-components';
import { display } from 'styled-utils';

export const Video = styled.video`
  width: 100%;
`;

export const ShowImage = styled.div`
  z-index: 0;

  .gatsby-image-wrapper {
    position: relative;
  }
`;

export const MobileImage = styled(ShowImage)`
  ${display({ display: ['block', 'none'] })};
`;

export const DesktopImage = styled(ShowImage)`
  ${display({ display: ['none', 'block'] })}; ;
`;
