import React from 'react';
import { renderBlockWithId } from 'helpers/block-with-id';
import { BlockType } from 'types';
import {
  OpenfitHomePageQuery,
  SanityHeroSlider,
  SanitySellPageMediaWithText,
  SanitySellPageSpacer,
  SanitySellPageDevicesFragment,
  SanityProgramCarouselFragment,
  SanityRichTextBlock2Fragment,
  SanitySellPageStickyCta,
  SanitySellPageStickyCtaShowHide,
  SanityFadeInCopyFragment,
  SanityBasicHeaderFragment,
  ParallaxCardsFragment,
  SanityMarqueeFragment,
  OptimizelyExperimentFragment,
  MediaWithTextFragment,
} from 'graphql-types';
import { SanityMediaWithText } from 'components/MediaWithText/SanityMediaWithText';
import { SanityHeroCarousel } from 'components/HeroCarousel/SanityHeroCarousel';
import { Spacer } from 'components/Spacer/Spacer';
import { SanityDevices } from 'components/Devices/SanityDevices';
import { SanityProgramCarousel } from 'components/ProgramCarousel/ProgramCarousel.sanity';
import { RichTextBlock2Sanity } from 'components/RichTextBlock2';
import { SellPageContainer } from 'components/Common/Layout';
import { SanityStickyCta } from 'components/StickyCta/SanityStickyCta';
import { StickyCtaShowHide } from 'components/StickyCta/StickyCtaShowHide';
import { SanityFadeInCopy } from 'components/FadeInCopy/FadeInCopy.sanity';
import { SanityBasicHeader as BasicHeader } from 'components/BasicHeader/BasicHeader';
import { Marquee } from 'components/Marquee/Marquee';
import { ParallaxCards } from 'components/ParallaxCards/ParallaxCards';
import { OptimizelyExperiment } from 'components/OptimizelyExperiment/OptimizelyExperiment';
import { SanitySplitMedia } from 'components/SplitMedia/SanitySplitMedia';

type BlocksArrayType = NonNullable<OpenfitHomePageQuery['page']>['blocks'];

export const renderHomePageBlock = (block: BlockType<BlocksArrayType>) =>
  renderBlockWithId(block, getBlock(block));

function getBlock(block: BlockType<BlocksArrayType>) {
  const type = block.__typename;
  const id = block._key;

  switch (type) {
    case 'SanityBasicHeader':
      return (
        <BasicHeader key={id} fields={block as SanityBasicHeaderFragment} />
      );
    case 'SanityHeroSlider':
      return <SanityHeroCarousel key={id} fields={block as SanityHeroSlider} />;
    case 'SanitySellPageMediaWithText':
      return (
        <SanityMediaWithText
          key={id}
          fields={block as SanitySellPageMediaWithText}
        />
      );
    case 'SanitySellPageSpacer':
      return (
        <Spacer
          key={id}
          backgroundColor={
            (block as SanitySellPageSpacer)?.backgroundColor?.hex
          }
          margin={(block as SanitySellPageSpacer)?.margin}
          marginMobile={(block as SanitySellPageSpacer)?.marginMobile}
        />
      );
    case 'SanityProgramCarousel':
      return (
        <SanityProgramCarousel
          key={id}
          fields={block as SanityProgramCarouselFragment}
        />
      );
    case `SanitySellPageDevices`:
      return (
        <SanityDevices
          key={id}
          fields={block as SanitySellPageDevicesFragment}
        />
      );
    case 'SanityRichTextBlock2':
      return (
        <RichTextBlock2Sanity
          key={id}
          fields={block as SanityRichTextBlock2Fragment}
          textContainer={SellPageContainer}
        />
      );
    case 'SanitySellPageStickyCta':
      return (
        <SanityStickyCta key={id} fields={block as SanitySellPageStickyCta} />
      );
    case 'SanitySellPageStickyCtaShowHide':
      return (
        <StickyCtaShowHide
          key={id}
          enableHide={(block as SanitySellPageStickyCtaShowHide)?.enableHide}
        />
      );
    case 'SanityOptimizelyExperiment':
      return (
        <OptimizelyExperiment
          key={id}
          fields={block as OptimizelyExperimentFragment}
        />
      );
    case 'SanityFadeInCopy':
      return (
        <SanityFadeInCopy key={id} fields={block as SanityFadeInCopyFragment} />
      );
    case 'SanityMarquee':
      return <Marquee key={id} data={block as SanityMarqueeFragment} />;
    case 'SanityParallaxCards':
      return <ParallaxCards key={id} data={block as ParallaxCardsFragment} />;
    case 'SanityMediaWithText':
      return <SanitySplitMedia data={block as MediaWithTextFragment} />;
    default:
      return `Unrecognized block type: ${type}`;
  }
}
