import styled, { keyframes } from 'styled-components';
import { typography } from '@bbnb/openfit-frontend-shared';

import {
  left,
  space,
  width,
  height,
  display,
  LeftProps,
  WidthProps,
  HeightProps,
} from 'styled-utils';

const scroll = keyframes`
  0% {
    transform: translate(0,0);
  }
  100% {
    transform: translate(-100%,0);
  }
`;

export const MarqueeWrapper = styled.div<WidthProps & HeightProps>`
  max-width: 100%;
  overflow: hidden;
  position: relative;
  ${width};
  ${height};
`;

export const MarqueeStyleWrapper = styled.div<
  LeftProps & { play: boolean; loopDuration?: number }
>`
  white-space: nowrap;
  animation: ${scroll} ${({ loopDuration }) => loopDuration || 15}s linear 0s
    infinite;
  animation-play-state: ${({ play }) => (play ? '' : 'paused')};
  position: absolute;
  transform: translate(0, 0);
  ${left};
`;

export const VideoList = styled.div`
  display: flex;
`;

export const CardContent = styled.div`
  width: 220px;
  display: flex;
  justify-content: center;
`;

export const Body = styled.div`
  ${typography('small')};
  font-weight: 700;
  ${space({ mx: ['10px'] })};

  p {
    margin: 16px 0;
  }

  height: 64px;
`;

export const CardMedia = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  height: 200px;

  video {
    border-top-left-radius: 6.20155px;
    border-top-right-radius: 6.20155px;
    width: 200px;
    height: 200px;
  }
`;

export const CardShadow = styled.div`
  flex-shrink: 0;
  margin: 25px 10px;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 10px;
  border-radius: 6.20155px;
  overflow: hidden;
  z-index: 2;
`;

export const Visibility = styled.div<{ visibility: boolean }>`
  opacity: ${({ visibility }) => (visibility ? 1 : 0)};
`;

export const MobilePlayButton = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  ${display({ display: ['flex', 'flex', 'none'] })};
`;
