import React from 'react';
import { useIsBrowser, useTimeout } from 'utils/general';

const MAX_AWAIT_TIME_TO_LOAD = 5000; // five seconds

type Experiment = Record<string, { id: string }>;

export const useExperiment = (): [boolean, Experiment] => {
  const isBrowser = useIsBrowser();
  const [campaign, addCampaign] = React.useState({});
  const [init, setInit] = React.useState<boolean>(false);

  const cancelForcedInitialization = useTimeout(() => {
    setInit(true);
  }, MAX_AWAIT_TIME_TO_LOAD);

  React.useEffect(() => {
    init && cancelForcedInitialization?.();
  }, [init]);

  React.useEffect(() => {
    if (!isBrowser) {
      return;
    }

    const variationMap = window.optimizely?.get?.('state')?.getVariationMap?.();

    if (!variationMap) {
      window.optimizely = window.optimizely || [];
      window.optimizely.push({
        type: 'addListener',
        filter: {
          type: 'lifecycle',
          name: 'campaignDecided',
        },
        handler: (event: {
          data: {
            decision: {
              experimentId: string;
              variationId: string;
            };
          };
        }) => {
          setInit(true);
          addCampaign({
            ...campaign,
            [event.data.decision.experimentId]: {
              id: event.data.decision.variationId,
            },
          });
        },
      });
      return;
    }

    addCampaign(variationMap);
    setInit(true);
  }, [isBrowser]);

  return [init, campaign];
};
