import React from 'react';
import { SanityBasicHeaderFragment, SanityImage } from 'graphql-types';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import {
  ContainerOverlay,
  ContextOverlay,
  DesktopLogo,
  LoginButton,
  LogoLink,
  MobileLogo,
} from './BasicHeader.styles';
import { useIsMobile } from 'hooks/use-media-query';
import { getUrl } from 'helpers/LinkObject';
import { getLoginURL } from 'utils/url';
import { awaitClick, logEvent } from 'helpers/Amplitude';
import { isLoggedIn } from 'helpers/Customer';
import { useIsBrowser } from 'utils/general';

export const SanityBasicHeader: React.FC<{
  fields: SanityBasicHeaderFragment;
}> = ({ fields }) => {
  const isMobile = useIsMobile();
  const isBrowser = useIsBrowser();

  const desktopImageItem = getSimplifiedImageData(
    fields.desktopImage as SanityImage
  );
  const mobileImageItem = getSimplifiedImageData(
    fields.mobileImage as SanityImage
  );
  if (!mobileImageItem || !desktopImageItem || !isBrowser) {
    return null;
  }

  return (
    <ContainerOverlay>
      <ContextOverlay>
        {isMobile ? (
          <LogoLink href="/" isMobile>
            <MobileLogo
              image={mobileImageItem}
              alt="mobile-logo"
              objectFit="contain"
            />
          </LogoLink>
        ) : (
          <LogoLink href="/">
            <DesktopLogo
              image={desktopImageItem}
              alt="desktop-logo"
              objectFit="contain"
            />
          </LogoLink>
        )}
        {!isLoggedIn() && (
          <LoginButton
            className="CTA"
            href={
              fields.ctaLinkObject?.page || fields.ctaLinkObject?.url
                ? getUrl(fields.ctaLinkObject, '')
                : getLoginURL()
            }
            variant={fields.ctaVariant}
            onClick={awaitClick((e) =>
              logEvent('Home-page: Click', { key: e.currentTarget.href })
            )}
          >
            {fields.ctaText}
          </LoginButton>
        )}
      </ContextOverlay>
    </ContainerOverlay>
  );
};
