import React, { FC, useEffect, useRef, useState } from 'react';
import {
  isMobile,
  OnOutsideClick,
  useScroll,
} from '@bbnb/openfit-frontend-shared';
import { SanityMarqueeFragment } from 'graphql-types';
import { useMarquee } from './MarqueeContext';
import {
  Body,
  CardContent,
  CardMedia,
  CardShadow,
  MobilePlayButton,
  Visibility,
} from './Marquee.styles';
import { Overlay, OverlayContent } from '../Common/Background';
import { PlainPlayButton } from '../Common/Background/Background.styles';
import { SanityRichText } from '../Common/SanityRichText';

export type MarqueeItemType = NonNullable<
  NonNullable<SanityMarqueeFragment['items']>[0]
>;

export const MarqueeItem: FC<{ id: number; item: MarqueeItemType }> = ({
  id,
  item,
}) => {
  const { top } = useScroll();
  const { addActiveTile, removeActiveTile } = useMarquee();
  const [isActive, setIsActive] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const play = () => videoRef?.current?.play();
  const pause = () => videoRef?.current?.pause();

  useEffect(() => {
    if (isMobile && isActive) {
      setIsActive(false);
    }
  }, [top]);

  useEffect(() => {
    if (isActive) {
      play();
    } else {
      pause();
    }
  }, [isActive]);

  return (
    <OnOutsideClick
      handleOutsideClick={() => {
        setIsActive(false);
      }}
    >
      <CardContent
        onClick={() => {
          if (isMobile) {
            setIsActive(!isActive);
            if (!isActive) {
              addActiveTile(id);
            } else {
              removeActiveTile(id);
            }
          }
        }}
        onMouseEnter={() => {
          if (!isMobile) {
            setIsActive(true);
          }
        }}
        onMouseLeave={() => {
          if (!isMobile) {
            setIsActive(false);
          }
        }}
      >
        <CardShadow>
          <CardMedia>
            <Visibility visibility={isActive}>
              <video
                ref={videoRef}
                loop
                src={item.videoFile?.asset?.url}
                playsInline
                muted
              />
            </Visibility>
            <OverlayContent>
              <Visibility visibility={!isActive}>
                <Overlay>
                  <img
                    src={item.image?.asset?.gatsbyImageData.images.fallback.src}
                    alt=""
                  />
                  <MobilePlayButton>
                    <PlainPlayButton alternateButton />
                  </MobilePlayButton>
                </Overlay>
              </Visibility>
            </OverlayContent>
          </CardMedia>
          <Body>
            <SanityRichText blocks={item._rawText} />
          </Body>
        </CardShadow>
      </CardContent>
    </OnOutsideClick>
  );
};
