import React, { FC } from 'react';
import { ParallaxCards as SharedParallaxCards } from '@bbnb/openfit-frontend-shared';
import { ParallaxCardsFragment } from 'graphql-types';
import { ParallaxCardsWrapper } from './ParallaxCards.styles';
import { ImageNextToContent } from './ImageNextToContent';
import { ContentOverImage } from './ContentOverImage';

export type CardType = NonNullable<
  NonNullable<ParallaxCardsFragment['cards']>[0]
>;

export const ParallaxCards: FC<{ data: ParallaxCardsFragment }> = ({
  data: { cards, backgroundColor, ...config },
}) => {
  const bg = backgroundColor?.hex || 'white';

  return (
    <ParallaxCardsWrapper bg={bg}>
      <SharedParallaxCards
        {...config}
        bg={bg}
        positionFromTop={config.positionFromTop || 110}
      >
        {(cards || []).map((card: CardType, i: number) => (
          <React.Fragment key={i}>
            {config.layout === 'content_over_image' ? (
              <ContentOverImage key={i} card={card} />
            ) : (
              <ImageNextToContent key={i} card={card} bg={bg} />
            )}
          </React.Fragment>
        ))}
      </SharedParallaxCards>
    </ParallaxCardsWrapper>
  );
};
