import React from 'react';
import { FadeInContainer, FadeInWrapper } from './FadeInCopy.styles';
import { useIsBrowser, useScroll } from '@bbnb/openfit-frontend-shared';
import { fadeInText } from './FadeInCopy.helper';

export const FadeInModules: React.FC = ({ children }) => {
  useScroll();
  const isBrowser = useIsBrowser();

  let vh = 0;
  if (isBrowser) {
    vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
  }
  const itemsCountArray = Array(React.Children.count(children));

  const elementRefs = React.useMemo(
    () =>
      Array.from(itemsCountArray).map(() => React.createRef<HTMLDivElement>()),
    []
  );

  const elementsData = fadeInText(elementRefs, vh);

  const wrappedChildren = React.Children.map(children, (child, index) => (
    <FadeInContainer
      key={index}
      ref={elementRefs[index]}
      style={elementsData?.[index].style}
    >
      <div style={elementsData?.[index].style}>{child}</div>
    </FadeInContainer>
  ));

  return <FadeInWrapper>{wrappedChildren}</FadeInWrapper>;
};
