import React from 'react';
import { Maybe, SanityBlock } from 'graphql-types';
import { SanityRichText } from 'components/Common/SanityRichText';
import { FadeInModules } from './FadeInModules';
import { Container, Wrapper } from './FadeInCopy.styles';

export type FadeInList = {
  content: Maybe<SanityBlock>[];
};
export type Props = {
  bg: Maybe<string>;
  contents: FadeInList[];
};

export const FadeInCopy: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <FadeInModules>
        {props.contents.map(({ content }, index) => (
          <Container key={index}>
            <SanityRichText blocks={content} />
          </Container>
        ))}
      </FadeInModules>
    </Wrapper>
  );
};
