import styled from 'styled-components';
import { top, TopProps, space, maxWidth } from 'styled-system';
import { BackgroundColorProps } from 'styled-system';
import { color } from 'styled-utils';

export const Wrapper = styled.div<BackgroundColorProps>`
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  ${maxWidth({ maxWidth: ['100%', '700px'] })};
  ${color}
`;
export const Container = styled.div`
  margin: 10px;
  text-align: center;
  ${space({ p: ['20px', '40px'] })};
`;
export const FadeInContainer = styled.div``;
export const FadeInWrapper = styled.div<TopProps>`
  ${top}
`;
