import styled from 'styled-components';
import { BackgroundColorProps } from 'styled-system';
import {
  color,
  space,
  width,
  fontSize,
  fontWeight,
  lineHeight,
  flexDirection,
  SpaceProps,
} from 'styled-utils';
import { SectionItemIconWrapper } from 'components/Product/BundleAddToCartBlock/ProductDescription.styles';

export const Section = styled.section<BackgroundColorProps & SpaceProps>`
  display: flex;
  ${flexDirection({ flexDirection: ['column-reverse', 'row'] })};

  > * {
    ${width({ width: ['100%', '50%'] })}
    flex-shrink: 0;
    flex-grow: 1;
  }

  ${space};
  ${color};
`;

export const Left = styled.div`
  ${space({ pb: ['125px', 0] })};
`;

export const Right = styled.div``;

export const Media = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  ${space({ pl: [0, '45px'] })};
`;

export const Text = styled.div`
  // todo This will be removed after we will introduce the theme styling
  padding: 30px;
  ${space({ pr: ['20px', 0] })};

  ${fontSize({ fontSize: ['16px'] })};
  ${fontWeight({ fontWeight: [400] })};

  p {
    ${fontSize({ fontSize: ['16px', '20px'] })};
    ${fontWeight({ fontWeight: [600] })};
  }

  p.heading1 {
    ${fontSize({ fontSize: ['40px', '60px'] })};
    ${lineHeight({ lineHeight: ['48px', '65px'] })};
  }

  p sup {
    font-size: 13px;
    vertical-align: top;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  ${SectionItemIconWrapper} {
    padding-top: 5px;
    margin-right: 5px;
  }
`;
