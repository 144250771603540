import React from 'react';
import { OptimizelyExperimentFragment } from 'graphql-types';
import { useExperiment } from './OptimizelyExperiment.hook';
import { renderOptimizelyExperimentBlocks } from './OptimizelyExperiment.blocks';
import { LoadingSpinner } from 'components/Common/Loading';
import { LoadingWrapper } from 'components/OptimizelyExperiment/OptimizelyExperiment.styles';
import { HeightProps } from 'styled-utils';

type Block = NonNullable<OptimizelyExperimentFragment['blocks']>[0];

export const OptimizelyExperiment: React.FC<{
  fields: OptimizelyExperimentFragment;
}> = ({ fields }) => {
  const [init, experiment] = useExperiment();

  if (!init) {
    const heights: HeightProps['height'] = fields?.height?.responsiveHeight
      ?.length
      ? (fields?.height?.responsiveHeight as HeightProps['height'])
      : [440, 440, 600];

    return (
      <LoadingWrapper height={heights}>
        <LoadingSpinner />
      </LoadingWrapper>
    );
  }

  const experimentMissMatch =
    init && !experiment[fields?.experiment?.experimentId || ''];

  if (experimentMissMatch) {
    const firstBlock = fields?.blocks?.[0];
    if (!firstBlock) {
      return null;
    }

    return (
      <>
        {firstBlock.blocks?.map(
          (block) => block && renderOptimizelyExperimentBlocks(block)
        )}
      </>
    );
  }

  const pickOnlyEnabledVariant = (block: Block): boolean => {
    return (
      block?.variationId ===
      experiment[fields?.experiment?.experimentId || '']?.id
    );
  };

  const blocks = fields?.blocks
    ?.filter(pickOnlyEnabledVariant)
    .reduce((acc, variantBlock = { blocks: [] }) => {
      if (!variantBlock.blocks) {
        return acc;
      }

      return [...acc, ...variantBlock.blocks];
    }, []);

  return (
    <>
      {blocks?.map((block) => block && renderOptimizelyExperimentBlocks(block))}
    </>
  );
};
