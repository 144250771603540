import styled from 'styled-components';
import { BackgroundColorProps } from 'styled-system';
import { color, flexDirection, maxWidth, space } from 'styled-utils';
import { Svg } from 'components/Common/Svg';
import { GatsbyImage } from 'components/Common/GatsbyImage';

export const ParallaxCardWrapper = styled.div<BackgroundColorProps>`
  display: flex;
  align-items: center;
  padding-bottom: 100px;
  justify-content: center;

  ${color};
  ${flexDirection({ flexDirection: ['column', 'row'] })};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${space({ pl: [0, 0, 55] })};
`;

export const Quote = styled(Svg)<{ gradient: string }>`
  flex-shrink: 0;
  height: 48px;
  ${space({ mt: ['30px', 0], mb: ['8px', 0] })};
  fill: ${({ gradient }) => `url(#${gradient})`};
`;

export const Text = styled.div`
  max-width: 450px;
  ${maxWidth({ maxWidth: ['342px', '450px'] })}
`;

export const Image = styled(GatsbyImage)`
  ${maxWidth({ maxWidth: ['343px', '524px'] })}
  width: 100%;
  height: 100%;
  border-radius: 10px;

  img {
    border-radius: 10px;
  }
`;
