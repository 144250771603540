import {
  isMobile,
  useIsBrowser,
  UserRole,
  useUserData,
} from '@bbnb/openfit-frontend-shared';
import { navigateToUrl } from 'helpers/General';

export enum RedirectDevices {
  MobileOnly,
  DesktopOnly,
  Both,
}

export type RedirectConfig = {
  roles: UserRole[];
  devices: RedirectDevices;
  url: string;
};

export const useRedirect = (
  redirects: RedirectConfig[]
): { loading: boolean } => {
  const isBrowser = useIsBrowser();
  const { role, loading } = useUserData();

  if (isBrowser && !loading) {
    const redirect = redirects.find(
      (config) =>
        config.roles.includes(role) &&
        (config.devices === RedirectDevices.Both ||
          (isMobile && config.devices === RedirectDevices.MobileOnly) ||
          (!isMobile && config.devices === RedirectDevices.DesktopOnly))
    );
    if (redirect) {
      navigateToUrl(redirect.url);
    }
  }

  return {
    loading,
  };
};
