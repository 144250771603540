import React, { FC } from 'react';

import { StandardMediaAssetFragment } from 'graphql-types';

import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { ResponsiveImage } from 'components/Common/GatsbyImage/ResponsiveImage';

import {
  Video,
  MobileImage,
  DesktopImage,
  ShowImage,
} from './AssetsPicker.styles';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { AssetsType } from './assets-picker.helper';

export type Image = {
  alt?: string;
  roundedCorners?: boolean;
  image?: {
    asset?: {
      url?: string;
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export type EmptyAsset = {
  assetsPicker: AssetsType.Empty;
};

export type VideoAsset = {
  assetsPicker: AssetsType.Video;
  video?: StandardMediaAssetFragment['video'];
};

export type MobileImageAsset = {
  assetsPicker: AssetsType.MobileImage;
  mobileImage?: Image;
};

export type DesktopImageAsset = {
  assetsPicker: AssetsType.DesktopImage;
  desktopImage?: Image;
};

export type ImageAsset = {
  assetsPicker: AssetsType.Image;
  image?: Image;
};

export type ResponsiveImageAsset = {
  assetsPicker: AssetsType.ResponsiveImage;
  desktopImage?: DesktopImageAsset['desktopImage'];
  mobileImage?: MobileImageAsset['mobileImage'];
};

export type AssetsPickerProps =
  | EmptyAsset
  | VideoAsset
  | ImageAsset
  | MobileImageAsset
  | DesktopImageAsset
  | ResponsiveImageAsset;

export const AssetsPicker: FC<AssetsPickerProps> = (props) => {
  switch (props.assetsPicker) {
    case 'mobileImage':
      return props.mobileImage ? (
        <MobileImage>
          <GatsbyImage
            alt={props.mobileImage.alt || ''}
            image={getSimplifiedImageData(props.mobileImage.image)}
          />
        </MobileImage>
      ) : null;

    case 'desktopImage':
      return props.desktopImage ? (
        <DesktopImage>
          <GatsbyImage
            alt={props.desktopImage.alt || ''}
            image={getSimplifiedImageData(props.desktopImage.image)}
          />
        </DesktopImage>
      ) : null;

    case 'image':
      return props.image ? (
        <ShowImage>
          <GatsbyImage
            alt={props.image.alt || ''}
            image={getSimplifiedImageData(props.image.image)}
          />
        </ShowImage>
      ) : null;

    case 'responsiveImage':
      return props.mobileImage || props.desktopImage ? (
        <ResponsiveImage
          className="Image"
          alt={props.mobileImage?.alt || props.desktopImage?.alt}
          mobileImage={getSimplifiedImageData(props.mobileImage?.image)}
          desktopImage={getSimplifiedImageData(props.desktopImage?.image)}
        />
      ) : null;

    case 'video':
      return props.video?.file?.asset?.url ? (
        <Video
          src={props.video.file.asset.url}
          loop
          muted
          autoPlay
          playsInline
        />
      ) : null;

    case 'empty':
    default:
      return null;
  }
};
