import React, { FC } from 'react';
import { SanityImage } from 'graphql-types';
import { SanityRichText } from 'components/Common/SanityRichText';
import { ResponsiveBackgroundImage } from 'components/Common/Background';
import { CardType } from './ParallaxCards';
import { Card, ContentOverImageLayout, Text } from './ContentOverImage.styles';

export const ContentOverImage: FC<{ card: CardType }> = ({ card }) => {
  return (
    <ContentOverImageLayout>
      <ResponsiveBackgroundImage
        height={{ mobile: 370, desktop: 650 }}
        images={{
          mobile: card.mobileImage as SanityImage,
          desktop: card.image as SanityImage,
        }}
      >
        <Card>
          {card._rawText ? (
            <Text>
              <SanityRichText blocks={card._rawText} />
            </Text>
          ) : null}
        </Card>
      </ResponsiveBackgroundImage>
    </ContentOverImageLayout>
  );
};
