import React from 'react';
import { FadeInList, FadeInCopy } from './FadeInCopy';
import { SanityFadeInCopyFragment } from 'graphql-types';

type FadeInText = NonNullable<
  NonNullable<SanityFadeInCopyFragment['fadeInTexts']>[0]
>;

export const SanityFadeInCopy: React.FC<{
  fields: SanityFadeInCopyFragment;
}> = ({ fields }) => {
  return (
    <FadeInCopy
      bg={fields.backgroundColor?.hex}
      contents={
        fields.fadeInTexts?.map(
          (contents: FadeInText): FadeInList => ({
            content: contents._rawText,
          })
        ) || []
      }
    />
  );
};
