import React from 'react';
import { graphql } from 'gatsby';
import { SeoMetadata } from 'components/SeoMetadata/SeoMetadata';
import { OpenfitHomePageQuery } from 'graphql-types';
import { renderHomePageBlock } from './HomePage.blocks';
import { useLogPageViewed } from 'helpers/Amplitude';
import { useTagStandardPageView } from 'helpers/Tealium';
import { useGTMTagStandardPageView } from 'helpers/GTM';
import { ImageLoadingWrapper } from 'components/Common/GatsbyImage';
import { RedirectDevices, useRedirect } from 'hooks/use-redirect';
import styled, { css } from 'styled-components';
import { poppinsFonts } from 'utils/poppins-typography';
import { activatePage } from 'components/OptimizelyExperiment/OptimizelyExperiment.helpers';

const FontStyle = styled.div<{ redesignTest: boolean }>`
  ${({ theme, redesignTest }) =>
    redesignTest
      ? css`
          .poppins-fonts {
            font-family: ${theme.fonts.secondary};
            * {
              font-family: ${theme.fonts.secondary};
            }

            ${poppinsFonts};
          }
        `
      : ''}
`;

export const HomePageTemplate: React.FC<{
  data: OpenfitHomePageQuery;
}> = ({ data }) => {
  const { blocks, seoMetadata } = data.page || {};

  useRedirect([
    {
      roles: ['subscriber'],
      devices: RedirectDevices.DesktopOnly,
      url: process.env.GATSBY_FIT_URL,
    },
    {
      roles: ['subscriber'],
      devices: RedirectDevices.MobileOnly,
      url: process.env.GATSBY_DOWNLOADTHEAPP_URL,
    },
  ]);

  useLogPageViewed();
  useTagStandardPageView('page_view');
  useGTMTagStandardPageView('page_view');

  activatePage('unentitled_homepage_qa');
  activatePage('unentitled_homepage_prod');

  return (
    <FontStyle redesignTest={Boolean(data.page?.redesignTest)}>
      <div className="poppins-fonts">
        {seoMetadata && <SeoMetadata metaData={seoMetadata} slug="" />}
        {blocks
          ?.map((block) => block && renderHomePageBlock(block))
          .map((block, index) => (
            <ImageLoadingWrapper
              key={index}
              eager={index < 2}
              children={block}
            />
          ))}
      </div>
    </FontStyle>
  );
};

export default HomePageTemplate;

export const query = graphql`
  query OpenfitHomePage($id: String!) {
    page: sanityOpenfitHomePage(id: { eq: $id }) {
      seoMetadata {
        ...SeoMetadata
      }
      hideHeader
      redesignTest
      blocks {
        ...SanityHeroSlider
        ...SanitySellPageSpacer
        ...SanitySellPageMediaWithText
        ...SanityProgramCarousel
        ...SanitySellPageDevices
        ...SanityRichTextBlock2
        ...SanitySellPageStickyCta
        ...SanitySellPageStickyCtaShowHide
        ...SanityFadeInCopy
        ...SanityBasicHeader
        ...SanityMarquee
        ...ParallaxCards
        ...OptimizelyExperiment
        ...MediaWithText
      }
    }
  }
`;
