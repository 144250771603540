import { AssetsPickerProps } from './AssetsPicker';
import { StandardMediaAssetFragment } from 'graphql-types';

import { createImageGatsby } from 'helpers/image-sanity';

export enum AssetsType {
  Empty = 'empty',
  Video = 'video',
  Image = 'image',
  Youtube = 'youtube',
  MobileImage = 'mobileImage',
  DesktopImage = 'desktopImage',
  ResponsiveImage = 'responsiveImage',
}

export const sanityToAssetsPickerProps = (
  data?: StandardMediaAssetFragment
): AssetsPickerProps =>
  data
    ? {
        ...data,
        assetsPicker: data.assetsPicker as AssetsPickerProps['assetsPicker'],
      }
    : { assetsPicker: AssetsType.Empty };

export const storyImage = (
  url: string,
  data?: { width?: number; height?: number },
  alt = 'Alt Text'
): AssetsPickerProps => ({
  assetsPicker: AssetsType.Image,
  image: {
    image: {
      asset: {
        gatsbyImageData: createImageGatsby(url, data),
      },
    },
    alt,
    roundedCorners: false,
  },
});
