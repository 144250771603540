import { isMobile } from '@bbnb/openfit-frontend-shared';

export const fadeInText = (
  elementRefs: React.RefObject<HTMLDivElement>[],
  vh: number
) => {
  if (elementRefs instanceof Array) {
    const elementStyle = elementRefs.map(
      (refOb: React.RefObject<HTMLDivElement>) => {
        const element = refOb.current;
        if (!element) {
          return {};
        }
        const position = element.getBoundingClientRect().y;
        const completelyShownPosition = vh - element.offsetHeight;
        const inView = position < vh;
        const completelyShown =
          position + element.offsetHeight - (isMobile ? 120 : 180) < vh;
        const height = element.offsetHeight;
        const animating = inView && !completelyShown;
        const reversePercentage = animating
          ? (9 + position - completelyShownPosition) / height
          : completelyShown
          ? 1
          : 0;
        return {
          style: {
            transition: `opacity .6s linear,transform .6s cubic-bezier(0.26,0.67,0.48,0.91)`,
            transform: `translateY(${animating ? '120px' : 0})`,
            opacity: animating
              ? 0 - reversePercentage
              : completelyShown
              ? 1
              : 0,
          },
        };
      }
    );
    return elementStyle;
  }
  return null;
};
