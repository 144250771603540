import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile, useScroll } from '@bbnb/openfit-frontend-shared';

import { useMarquee } from './MarqueeContext';
import { MarqueeStyleWrapper, MarqueeWrapper } from './Marquee.styles';

export const GenericMarquee: FC<{ loopDuration?: number }> = ({
  children,
  loopDuration,
}) => {
  const { isAnyTileActive, clearAll } = useMarquee();
  const { top } = useScroll();

  const reference = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [play, setPlay] = useState(true);

  useEffect(() => {
    clearAll();
    setPlay(true);
  }, [top]);

  useLayoutEffect(() => {
    if (reference?.current) {
      setDimensions({
        width: Math.round(reference?.current?.offsetWidth) || 0,
        height: Math.round(reference?.current?.offsetHeight) || 0,
      });
    }
  }, []);

  return (
    <MarqueeWrapper
      width={dimensions.width}
      height={dimensions.height}
      onClick={() => {
        if (isMobile) {
          setPlay(!isAnyTileActive);
        }
      }}
      onMouseEnter={() => !isMobile && setPlay(false)}
      onMouseLeave={() => !isMobile && setPlay(true)}
    >
      <MarqueeStyleWrapper
        loopDuration={loopDuration}
        play={play}
        ref={reference}
      >
        {children}
      </MarqueeStyleWrapper>
      <MarqueeStyleWrapper
        loopDuration={loopDuration}
        play={play}
        left={dimensions.width}
      >
        {children}
      </MarqueeStyleWrapper>
    </MarqueeWrapper>
  );
};
