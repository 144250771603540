import styled from 'styled-components';
import { BackgroundColorProps } from 'styled-system';
import { flexDirection, maxWidth } from 'styled-utils';

export const ContentOverImageLayout = styled.div<BackgroundColorProps>`
  display: flex;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 16px;

  img {
    border-radius: 16px;
  }

  ${maxWidth({ maxWidth: ['343px', '650px'] })};
  ${flexDirection({ flexDirection: ['column', 'row'] })};
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 6px;
`;

export const Text = styled.div`
  ${maxWidth({ maxWidth: ['311px', '543px'] })};
`;
