import React from 'react';
import { renderBlockWithId } from 'helpers/block-with-id';
import { BlockType } from 'types';

import {
  OpenfitCollectionPageBySlugQuery,
  SanityHeroSlider,
} from 'graphql-types';

import { SanityHeroCarousel } from 'components/HeroCarousel/SanityHeroCarousel';

type BlocksArrayType = NonNullable<
  OpenfitCollectionPageBySlugQuery['page']
>['blocks'];

export const renderOptimizelyExperimentBlocks = (
  block: BlockType<BlocksArrayType>
) => renderBlockWithId(block, getBlock(block));

function getBlock(block: BlockType<BlocksArrayType>) {
  const type = block._type;
  const id = block._key;

  switch (type) {
    case 'heroSlider':
      return <SanityHeroCarousel key={id} fields={block as SanityHeroSlider} />;

    default:
      return `Unrecognized block type: ${type}`;
  }
}
